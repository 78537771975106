import React from 'react';
import { graphql } from 'gatsby';

import Wrapper from '../components/Wrapper';
import Page from '../components/Page';

const page = ({ data }) => {
  const {
    sanityPage: { seoSettings, title },
  } = data;
  return (
    <Wrapper className="page" seo={seoSettings} title={title}>
      <Page {...data.sanityPage} />
    </Wrapper>
  );
};

export const PageQuery = graphql`
  query PageQuery($id: String!) {
    sanityPage(id: { eq: $id }) {
      id
      title
      _rawContent(resolveReferences: { maxDepth: 10 })
      seoSettings {
        metaDescription
        title
      }
    }
  }
`;

export default page;
