import React from 'react';

import styled from 'styled-components';

import Content from './Content';

import { media } from './theme';
const Page = props => {
  const { title, _rawContent } = props;
  // console.log(props);
  return (
    <PageMain>
      <h1 className="page-title">{title}</h1>
      <Content>{_rawContent}</Content>
    </PageMain>
  );
};

const PageMain = styled.main`
  padding: 1rem;
  line-height: 1.5;

  ${media.break`
    padding: 4rem 3.5rem;
    overflow: scroll;
  `}
`;

export default Page;
